import React from "react";

import { useMessageGetter } from "react-message-context";

import { ErrorPage } from "../ErrorPage";

export const UnauthorizedPage: React.FC = () => {
    const messages = useMessageGetter("unauthorized");

    return <ErrorPage title={messages("title")} message={messages("message")} />;
};
