import { useEffect } from "react";

import { SQSMeetingMessage, SQSMessage } from "../../domain";
import useWebSocketContext from "../useWebSocketContext";

/**
 * A hook to register a callback for "meeting" messages.
 *
 * @param callback Callback to call with messages of type "meeting"
 */
export function useMeetingMessageCallback(callback: (msg: SQSMeetingMessage) => void) {
    const { ws } = useWebSocketContext();

    useEffect(() => {
        if (ws) {
            const messageHandler = (event: MessageEvent) => {
                try {
                    const data = JSON.parse(event.data) as SQSMessage;
                    // TODO: Better data validation before passing to callback?
                    if (data.topic === "meeting") {
                        callback(data as SQSMeetingMessage);
                    }
                } catch (e) {}
            };
            ws.addEventListener("message", messageHandler);
            return () => ws.removeEventListener("message", messageHandler);
        }
    }, [ws, callback]);
}
