import React from "react";

import { Col, Row, Action } from "@op/opux";
import styled from "styled-components";

import "./styles.scss";
import { useMessageGetter } from "react-message-context";
import { useAppState } from "../../state";

const FooterContainer = styled.footer`
    border-top: 1px solid #dee2e6;
    width: 100vw;
    padding-top 1rem;
    padding-bottom: 1rem;
    bottom: 0;
    left: 0;
`;

const Ul = styled.ul`
    list-style-type: none;
    display: block;
`;
const Li = styled.li`
    padding: 0.6rem 0;
    display: inline-block;
    list-style-type: none;
    margin-right: 3.2rem;
    :last-child {
        margin-right: 0;
    }
`;

export const Footer: React.FC = () => {
    const [year] = React.useState(new Date().getFullYear());
    const { playerPage } = useAppState();
    const messages = useMessageGetter("footer");
    if (playerPage) {
        return null;
    }
    return (
        <FooterContainer>
            <Row between>
                <Col md={6} className="order-2 order-md-1" style={{ display: "flex", alignItems: "center" }}>
                    <p>
                        &copy; {year} {messages("copyright")}
                    </p>
                </Col>
                <Col md={6} className="text-md-right order-1 order-md-2">
                    <Ul>
                        <Li>
                            <Action target="_blank" href="https://op-koti.fi/evasteet">
                                {messages("cookies")}
                            </Action>
                        </Li>
                        <Li>
                            <Action target="_blank" href="https://op-koti.fi/henkilotietojen-kasittely">
                                {messages("privacyPolicy")}
                            </Action>
                        </Li>
                    </Ul>
                </Col>
            </Row>
        </FooterContainer>
    );
};
