import React, { useCallback, useEffect, useState } from "react";

import { Button } from "@op/opux";
import { LocalVideoTrack } from "twilio-video";
import { useMessageGetter } from "react-message-context";

import useVideoContext from "../../../hooks/useVideoContext";
import useMediaStreamTrack from "../../../hooks/useMediaStreamTrack";
import { useVideoInputDevices } from "../../../hooks/deviceHooks";
import { defaultLocalTrackOptions } from "../../../globals";
import FlipCam from "../../icons/FlipCam";

interface Props {
    isMobile?: boolean;
}

export const FlipCameraButton: React.FC<Props> = ({ isMobile }) => {
    const { localTracks } = useVideoContext();
    const [supportsFacingMode, setSuppotsFacingMode] = useState<boolean | null>(null);
    const videoTrack = localTracks.find((t) => t.kind === "video") as LocalVideoTrack | undefined;
    const mediaStreamTrack = useMediaStreamTrack(videoTrack);
    const videoDevices = useVideoInputDevices();
    const actions = useMessageGetter("actions");

    useEffect(() => {
        const currentFacingMode = mediaStreamTrack?.getSettings().facingMode;
        if (currentFacingMode && supportsFacingMode === null) {
            setSuppotsFacingMode(true);
        }
    }, [mediaStreamTrack, supportsFacingMode]);

    const toggleFacingMode = useCallback(() => {
        const newFacingMode = mediaStreamTrack?.getSettings().facingMode === "user" ? "environment" : "user";
        videoTrack
            ?.restart({
                ...defaultLocalTrackOptions,
                facingMode: newFacingMode,
            })
            .catch((err: DOMException) => {
                console.error(`Restarting the video track with facing mode '${newFacingMode}' failed`, err);
            });
    }, [mediaStreamTrack, videoTrack]);

    return supportsFacingMode && videoDevices.length > 1 ? (
        <Button
            size={isMobile ? "default" : "large"}
            onClick={toggleFacingMode}
            minified
            className="control"
            icon={FlipCam}
            iconProps={{ type: "default" }}
            iconLabel={actions("flipCamera")}
            disabled={!videoTrack}
        />
    ) : null;
};
