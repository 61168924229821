import React, { useState } from "react";

import { Button } from "@op/opux";

import { useAppState } from "../../../state";
import { Message } from "react-message-context";

interface Props {
    meetingId: string;
    isMobile?: boolean;
}

export const GoLiveButton: React.FC<Props> = ({ meetingId, isMobile }) => {
    const [goingLive, setGoingLive] = useState(false);
    const { updateMeeting } = useAppState();

    const onGoLive = () => {
        setGoingLive(true);
        updateMeeting(meetingId, "live").catch((err) => {
            console.error(err);
            setGoingLive(false);
        });
    };
    return (
        <Button
            size={isMobile ? "default" : "large"}
            onClick={onGoLive}
            className="control"
            finish
            nonInteractive={goingLive}
            loading={goingLive}
        >
            <Message id="actions.live" />
        </Button>
    );
};
