import { ConnectOptions } from "twilio-video";

export const apiConfig = {
    baseUrl: process.env.REACT_APP_API_URL || "https://axzc3vnde8.execute-api.eu-central-1.amazonaws.com/dev",
    socketUrl: process.env.REACT_APP_WS_URL || "wss://ks6171g1h6.execute-api.eu-central-1.amazonaws.com/dev",
};

// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
export const connectionOptions: ConnectOptions = {
    audio: true,
    video: {
        height: 720,
        width: 1280,
        frameRate: 24,
    },
    // Bandwidth Profile, Dominant Speaker, and Network Quality
    // features are only available in Small Group or Group Rooms.
    // Please set "Room Type" to "Group" or "Small Group" in your
    // Twilio Console: https://www.twilio.com/console/video/configure
    bandwidthProfile: {
        video: {
            mode: "presentation",
            maxTracks: 10,
            dominantSpeakerPriority: "standard",
            renderDimensions: {
                high: { height: 1080, width: 1920 },
                standard: { height: 720, width: 1280 },
                low: { height: 176, width: 144 },
            },
        },
    },
    dominantSpeaker: true,
    networkQuality: { local: 1, remote: 1 },

    // Comment this line if you are playing music.
    maxAudioBitrate: 16000,

    // VP8 simulcast enables the media server in a Small Group or Group Room
    // to adapt your encoded video quality for each RemoteParticipant based on
    // their individual bandwidth constraints. This has no effect if you are
    // using Peer-to-Peer Rooms.
    preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],
    region: "de1", // Override default gll, use Germany instead
};
