import React from "react";

import { IconPerson } from "@op/opux-icons/service";
import styled from "styled-components";

import useParticipants from "../../../hooks/useParticipants";

const TextContainer = styled.div`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    line-height: 100%;
`;

export const ParticipantCount: React.FC = () => {
    const participants = useParticipants();
    return (
        <>
            <TextContainer className="text-white">
                <IconPerson onDarkBg size="small" style={{ transform: "translateY(-2px)" }} />
                <span>{participants.length}</span>
            </TextContainer>
        </>
    );
};
