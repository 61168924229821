import React, { useState } from "react";

import { Button } from "@op/opux";
import { Modal } from "@op/opux-modal";

import { useAppState } from "../../../state";
import { Message, useMessageGetter } from "react-message-context";

interface Props {
    meetingId: string;
    isMobile?: boolean;
}

export const CompleteButton: React.FC<Props> = ({ meetingId, isMobile }) => {
    const [completing, setCompleting] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const { updateMeeting } = useAppState();
    const messages = useMessageGetter("player.completeDialog");

    const onComplete = () => {
        if (completing) {
            return;
        }
        setCompleting(true);
        updateMeeting(meetingId, "completed").catch((err) => {
            console.error(err);
            setCompleting(false);
        });
    };

    return (
        <>
            <Button
                size={isMobile ? "default" : "large"}
                onClick={() => setModalOpen(true)}
                className="control"
                primary
                nonInteractive={modalOpen || completing}
                loading={completing}
            >
                <Message id="actions.complete" />
            </Button>
            <Modal
                isOpen={modalOpen}
                onUpdate={(state) => setModalOpen(state.open)}
                appElement={document.getElementById("root") || undefined}
                title={messages("title")}
                footer={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button compact onClick={() => setModalOpen(false)}>
                            <Message id="actions.cancel" />
                        </Button>
                        {/* Compact button doesn't allow "primary" flag, hence as a class */}
                        <Button nonInteractive={completing} compact onClick={onComplete} className="ds-button--primary">
                            <Message id="actions.close" />
                        </Button>
                    </div>
                }
            >
                <p>{messages("message")}</p>
            </Modal>
        </>
    );
};
