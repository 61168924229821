import React, { useEffect, useState } from "react";

import { Modal } from "@op/opux-modal";
import { Message, useMessageGetter } from "react-message-context";
import { Button } from "@op/opux";
import useWebSocketContext from "../../../hooks/useWebSocketContext";

/**
 * Component for displaying a modal for user when the WebSocket connection is not
 * healthy anymore.
 */
export const ConnectionBrokenAlert: React.FC = () => {
    const { pageRefreshNeeded } = useWebSocketContext();
    const [open, setOpen] = useState(false);
    const messages = useMessageGetter("connectionBrokenAlert");

    const onRefresh = () => {
        window.location.reload();
    };

    useEffect(() => {
        setOpen(pageRefreshNeeded);
    }, [pageRefreshNeeded, setOpen]);

    return (
        <Modal
            appElement={document.getElementById("root") || undefined}
            title={messages("title")}
            isOpen={open}
            onUpdate={(state) => setOpen(state.open)}
            footer={
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Button compact onClick={onRefresh}>
                        <Message id="actions.reload" />
                    </Button>
                </div>
            }
        >
            <div>{messages("description")}</div>
        </Modal>
    );
};
