import React from "react";
import { sendComponentError } from "../../lib/monitoring";
import { ErrorPage } from "../ErrorPage";

interface State {
    /**
     * Whether to show error or not.
     */
    showError: boolean;
}

interface Props {
    /**
     * Message getter
     *
     * @param key Message key
     */
    messages(key: string): string;
}

export class ErrorBoundary extends React.Component<Props, State> {
    state = {
        showError: false,
    };

    /**
     * Called whenever an error occurs, and returns a new state for the component.
     *
     * @param error The error that occurred
     */
    static getDerivedStateFromError(error: Error) {
        return {
            showError: true,
        };
    }

    /**
     * Called when component catches an error.
     *
     * @param error The error
     * @param errorInfo Detailed error info
     */
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.warn("Boundary caught an error", error);
        sendComponentError(error);
    }

    /**
     * Just render the children always
     */
    render() {
        const { showError } = this.state;
        const { messages } = this.props;
        if (showError) {
            return <ErrorPage title={messages(`unknown.title`)} message={messages(`unknown.message`)} />;
        }
        return this.props.children;
    }
}
