import React, { useCallback, useState } from "react";

import { Button } from "@op/opux";
import { IconCallHangup } from "@op/opux-icons/action";
import { Modal } from "@op/opux-modal";
import { Message, useMessageGetter } from "react-message-context";

import useVideoContext from "../../../hooks/useVideoContext";

export const LeaveButton: React.FC<{ isMobile?: boolean }> = ({ isMobile }) => {
    const { room } = useVideoContext();
    const labels = useMessageGetter("actions");
    const [modalOpen, setModalOpen] = useState(false);
    const messages = useMessageGetter("player.leaveDialog");

    const onLeave = useCallback(() => {
        room.disconnect();
    }, [room]);

    return (
        <>
            <Button
                size={isMobile ? "default" : "large"}
                minified
                icon={IconCallHangup}
                iconLabel={labels("leave")}
                onClick={() => setModalOpen(true)}
                iconProps={{ type: "default" }}
                className="control"
            />
            <Modal
                isOpen={modalOpen}
                onUpdate={(state) => setModalOpen(state.open)}
                appElement={document.getElementById("root") || undefined}
                title={messages("title")}
                footer={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button compact onClick={() => setModalOpen(false)}>
                            <Message id="actions.cancel" />
                        </Button>
                        {/* Compact button doesn't allow "primary" flag, hence as a class */}
                        <Button compact onClick={onLeave} className="ds-button--primary">
                            <Message id="actions.leave" />
                        </Button>
                    </div>
                }
            >
                <p>{messages("message")}</p>
            </Modal>
        </>
    );
};
