import React from "react";

import { CircledIcon, CircledIconProps } from "@op/opux";

const BubbleCircled: React.FC<CircledIconProps> = (props) =>
    React.createElement(CircledIcon, {
        ...props,
        path:
            "m 29.846018,53.006185 c -0.547141,0 -0.989146,-0.44415 -0.989146,-0.991292 V 44.749716 H 18.806639 c -2.231478,0 -3.040389,-1.819513 -3.040389,-3.042534 V 22.59586 c 0,-2.231478 1.817367,-3.038243 3.040389,-3.038243 H 49.18907 c 2.231478,0 3.040389,1.819513 3.040389,3.040389 v 19.115613 c 0,2.229332 -1.817368,3.038243 -3.040389,3.038243 H 38.55951 l -8.016155,7.968951 c -0.186672,0.184526 -0.439859,0.287517 -0.699483,0.287517 z M 18.808785,21.535908 c -0.388363,0.0086 -1.059952,0.158778 -1.059952,1.057807 v 19.115612 c 0.0086,0.388363 0.158778,1.059952 1.059952,1.059952 h 11.037233 c 0.547141,0 0.989145,0.442005 0.989145,0.989146 v 5.87694 L 37.4545,43.056797 c 0.184526,-0.184526 0.437713,-0.287518 0.697336,-0.287518 h 11.041525 c 0.384072,-0.0086 1.057806,-0.158778 1.057806,-1.062097 V 22.59586 c -0.0086,-0.386217 -0.158778,-1.057806 -1.057806,-1.057806 H 18.808785 Z M 34,68 C 15.249148,68 0,52.748706 0,34 0,15.251294 15.251294,0 34,0 52.750852,0 68,15.253439 68,34 68,52.746561 52.746561,68 34,68 Z M 34,1.9804367 C 16.343431,1.9804367 1.9804367,16.343431 1.9804367,34 1.9804367,51.656569 16.339139,66.019563 34,66.019563 51.660861,66.019563 66.019563,51.656569 66.019563,34 66.019563,16.343431 51.656569,1.9804367 34,1.9804367 Z",
    });

export default BubbleCircled;
