import { useContext } from "react";
import { TermsOfServiceContext } from "../../components/TermsOfServiceProvider";

export default function useTermsOfServiceContext() {
    const context = useContext(TermsOfServiceContext);
    if (!context) {
        throw new Error("useTermsOfServiceContext() must be used within a TermsOfServiceContext");
    }
    return context;
}
