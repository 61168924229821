import { useContext } from "react";
import { WebSocketContext } from "../../components/WebSocketProvider";

export default function useWebSocketContext() {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error("useWebSocketContext() must be used within a <WebSocketProvider />");
    }
    return context;
}
