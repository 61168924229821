import React from "react";

import { CircledIcon, CircledIconProps } from "@op/opux";

const InfoCircled: React.FC<CircledIconProps> = (props) =>
    React.createElement(CircledIcon, {
        ...props,
        path:
            "M 34,0 C 15.251294,0 0,15.253439 0,34 0,52.746561 15.251294,68 34,68 52.746561,68 68,52.748706 68,34 68,15.251294 52.746561,0 34,0 Z m 0,66.019563 C 16.343431,66.019563 1.9804367,51.656569 1.9804367,34 1.9804367,16.343431 16.343431,1.9804367 34,1.9804367 51.654424,1.9804367 66.019563,16.345576 66.019563,34 66.019563,51.654424 51.654424,66.019563 34,66.019563 Z M 45.554335,20.795658 H 22.44781 c -0.911902,0 -1.650006,0.74025 -1.650006,1.650007 V 45.55219 c 0,0.911902 0.74025,1.650006 1.650006,1.650006 h 23.106525 c 0.911903,0 1.650007,-0.74025 1.650007,-1.650006 V 22.445665 c 0,-0.911903 -0.74025,-1.650007 -1.650007,-1.650007 z m -0.33043,24.428247 h -22.44781 v -22.44781 h 22.44781 z M 31.689133,40.932601 h 4.621734 c 0.547141,0 0.991291,-0.44415 0.991291,-0.991291 0,-0.547141 -0.44415,-0.991291 -0.991291,-0.991291 v 0 h -0.991291 v -6.271741 c 0,-0.547141 -0.44415,-0.991291 -0.991291,-0.991291 v 0 h -1.980437 c -0.547141,0 -0.991291,0.44415 -0.991291,0.991291 0,0.547142 0.44415,0.991292 0.991291,0.991292 v 0 h 0.991291 v 5.282595 h -1.650006 c -0.547141,0 -0.991291,0.44415 -0.991291,0.991291 0,0.547141 0.44415,0.991291 0.991291,0.991291 v 0 z M 35.484791,28.05869 c 0,0.819639 -0.665152,1.484791 -1.484791,1.484791 -0.819639,0 -1.484791,-0.665152 -1.484791,-1.484791 0,-0.819639 0.665152,-1.484791 1.484791,-1.484791 0.819639,0 1.484791,0.665152 1.484791,1.484791 z",
    });

export default InfoCircled;
