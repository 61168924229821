import React, { useState } from "react";

import { Button } from "@op/opux";
import { useMessageGetter } from "react-message-context";
import styled from "styled-components";

import { ReactComponent as EndedIllustration } from "../../../../assets/img/Ended_user_illustration.svg";
import { Session } from "../../../../domain";
import { RealtorModal } from "../../../RealtorModal";
import { IconBubbleCircled, IconInfoCircled, IconRoomsCircled } from "../../../icons";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    text-align: center;
`;

const ImgContainer = styled.div`
    padding: 0 1.6rem 2.4rem 1.6rem;
    flex: 1;
    width: 70%;
    max-width: 400px;
`;

const TxtContainer = styled.section`
    margin-bottom: 2.4rem;
    & > p {
        margin-top: 0.4rem;
    }
`;

const BtnContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const BtnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 1.6rem;
`;

const BtnLabel = styled.span`
    margin-top: 0.4rem;
`;

interface ViewerLobbyProps {
    session: Session;
}

export const EndedLobby: React.FC<ViewerLobbyProps> = ({ session }) => {
    const messages = useMessageGetter("lobby.ended");
    const misc = useMessageGetter("misc");
    const [open, setOpen] = useState(false);

    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);

    return (
        <Container>
            <ImgContainer>
                <EndedIllustration />
            </ImgContainer>
            <TxtContainer>
                <h1>{messages("title")}</h1>
                <p>{messages("message")}</p>
            </TxtContainer>

            <BtnContainer>
                <BtnWrapper>
                    <Button
                        icon={IconInfoCircled}
                        minified
                        iconLabel={misc("info")}
                        href={session.info1 || undefined}
                        target="_blank"
                        disabled={!session.info1}
                        iconProps={{
                            size: "small",
                        }}
                        ghost
                    />
                    <BtnLabel>{misc("info")}</BtnLabel>
                </BtnWrapper>
                <BtnWrapper>
                    <Button
                        icon={IconRoomsCircled}
                        minified
                        ghost
                        iconLabel={misc("floorPlan")}
                        href={session.info2 || undefined}
                        target="_blank"
                        disabled={!session.info2}
                        iconProps={{
                            size: "small",
                        }}
                    />
                    <BtnLabel>{misc("floorPlan")}</BtnLabel>
                </BtnWrapper>
                <BtnWrapper>
                    <Button
                        icon={IconBubbleCircled}
                        minified
                        iconLabel={misc("realtor")}
                        onClick={onOpen}
                        iconProps={{
                            size: "small",
                        }}
                        ghost
                    />
                    <BtnLabel>{misc("realtor")}</BtnLabel>
                </BtnWrapper>
            </BtnContainer>
            <RealtorModal session={session} open={open} onClose={onClose} />
        </Container>
    );
};
