import React from "react";

import { Participant } from "twilio-video";
import { useMessageGetter } from "react-message-context";

import usePublications from "../../../hooks/usePublications";
import useVideoContext from "../../../hooks/useVideoContext";
import Publication from "../Publication";
import { ErrorPage } from "../../ErrorPage";
import { useReportMultipleVideosPublished } from "../../../hooks/useReportMultipleVideosPublished";

interface Props {
    participant: Participant;
    disableAudio?: boolean;
}

export const ParticipantTracks: React.FC<Props> = ({ participant }) => {
    const { room } = useVideoContext();
    const publications = usePublications(participant);
    const messages = useMessageGetter("player");
    // Send an error if participant has multiple video streams published
    useReportMultipleVideosPublished(participant);
    const isLocal = participant === room.localParticipant;
    // Prevent awkward bugs where participant may have "empty" publications
    // published which may result in broken UI layout as design covers only
    // one video publication
    const video = publications.find((p) => p.kind === "video");
    const audio = publications.find((p) => p.kind === "audio");
    const hasVideo = !!video;

    return (
        <>
            {video && (
                <Publication
                    key="video"
                    publication={video}
                    participant={participant}
                    isLocal={isLocal}
                    videoPriority="high"
                />
            )}
            {audio && (
                <Publication
                    key="audio"
                    publication={audio}
                    participant={participant}
                    isLocal={isLocal}
                    videoPriority="high"
                />
            )}
            {!hasVideo && <ErrorPage title={messages("videoDisabled")} message={messages("hostVideoDisabled")} />}
        </>
    );
};
