import React from "react";

import styled from "styled-components";

const Container = styled("div")({
    display: "flex",
    alignItems: "flex-end",
    "& div": {
        width: 2,
        border: "1px solid black",
        boxSizing: "content-box",
        "&:not(:last-child)": {
            borderRight: "none",
        },
    },
});

const STEP = 3;

export function NetworkQualityIndicator({ qualityLevel }: { qualityLevel: number | null }) {
    return qualityLevel === null ? null : (
        <Container>
            {[0, 1, 2, 3, 4].map((level) => (
                <div
                    key={level}
                    style={{
                        height: `${STEP * (level + 1)}px`,
                        background: qualityLevel > level ? "#0c0" : "#040",
                    }}
                />
            ))}
        </Container>
    );
}
