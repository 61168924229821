import React, { useRef, useEffect } from "react";

import { Track, LocalVideoTrack, RemoteVideoTrack } from "twilio-video";
import styled from "styled-components";
import useMediaStreamTrack from "../../hooks/useMediaStreamTrack";
import useVideoTrackDimensions from "../../hooks/useVideoTrackDimensions";

const Video = styled("video")({
    width: "100%",
    maxHeight: "100vh",
});

interface VideoTrackProps {
    track: LocalVideoTrack | RemoteVideoTrack;
    isLocal?: boolean;
    priority?: Track.Priority | null;
}

export default function VideoTrack({ track, isLocal, priority }: VideoTrackProps) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ref = useRef<HTMLVideoElement>(null!);
    const mediaStreamTrack = useMediaStreamTrack(track);
    const dimensions = useVideoTrackDimensions(track);
    const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

    useEffect(() => {
        const el = ref.current;
        el.muted = true;
        if (track.setPriority && priority) {
            track.setPriority(priority);
        }
        track.attach(el);
        return () => {
            track.detach(el);
            if (track.setPriority && priority) {
                // Passing `null` to setPriority will set the track's priority to that which it was published with.
                track.setPriority(null);
            }
        };
    }, [track, priority]);

    // The local video track is mirrored.
    const isFrontFacing = mediaStreamTrack?.getSettings().facingMode !== "environment";

    return (
        <Video
            ref={ref}
            style={{
                transform: isLocal && isFrontFacing ? "rotateY(180deg)" : "",
                objectFit: isPortrait ? "contain" : "cover",
            }}
        />
    );
}
