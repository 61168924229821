import React from "react";

import { Button, ButtonRow, Tile } from "@op/opux";
import { Message, useMessageGetter } from "react-message-context";
import styled from "styled-components";

import { Meeting } from "../../../../domain/meeting";

interface HostLobbyProps {
    meeting?: Meeting | null;
    demoPending?: boolean;
    livePending?: boolean;
    onDemo: () => void;
    onLive: () => void;
}

const Divider = styled.div`
    height: 4.2rem;
    border-left: 1px solid #dcdcdc;
`;

const ChildrenContainer = styled.div`
    margin-top: 1.8rem;
`;

export const HostLobby: React.FC<HostLobbyProps> = ({
    meeting,
    demoPending,
    livePending,
    onDemo,
    onLive,
    children,
}) => {
    const messages = useMessageGetter("lobby.realtor");
    return (
        <>
            <Tile title={messages("title")}>
                <p>{messages("message1")}</p>
                <p>{messages("message2")}</p>

                {children && <ChildrenContainer>{children}</ChildrenContainer>}

                {meeting?.state !== "completed" && (
                    <ButtonRow>
                        <Button
                            onClick={() => onLive()}
                            finish
                            loading={livePending}
                            nonInteractive={livePending || demoPending}
                        >
                            <Message id="actions.live" />
                        </Button>
                        <Divider />
                        <Button
                            disabled={meeting?.state === "live"}
                            onClick={() => onDemo()}
                            loading={demoPending}
                            nonInteractive={demoPending || livePending}
                        >
                            <Message id="actions.demo" />
                        </Button>
                    </ButtonRow>
                )}
            </Tile>
        </>
    );
};
