import { useEffect } from "react";

import { Participant } from "twilio-video";

import { sendComponentError } from "../../lib/monitoring";
import usePublications from "../usePublications";

/**
 * This is a hook that monitors participant's publications and reports an error in case
 * the participant has multiple videos published. This is part of debugging strategy for
 * the unreproduceable bug discovered early in the testing (and later in production). See
 * [HOMEALONE-105](https://oppjira.eficode.com/browse/HOMEALONE-105) for more details.
 *
 * @param participant Participant to be watched
 */
export function useReportMultipleVideosPublished(participant: Participant) {
    const publications = usePublications(participant);

    useEffect(() => {
        const videos = publications.filter((p) => p.kind === "video");
        if (videos.length > 1) {
            // Since we don't have custom error logging, send this one as component error
            sendComponentError(
                new Error(
                    `Participant ${participant.identity} with SID ${participant.sid} has published ${videos.length} video streams`,
                ),
            );
        }
    }, [participant, publications]);
}
