import { useEffect } from "react";

import { Room, TwilioError } from "twilio-video";
import { useAppState } from "../../../state";

export default function useHandleOnDisconnect(room: Room, onDisconnect: (reason?: "host-ended") => void) {
    const { setTwilioError } = useAppState();
    useEffect(() => {
        const callback = (room: Room, error?: TwilioError) => {
            if (error && error.message === "Room completed") {
                onDisconnect("host-ended");
            } else {
                if (error) {
                    setTwilioError(error);
                }
                onDisconnect();
            }
        };
        room.on("disconnected", callback);
        return () => {
            room.off("disconnected", callback);
        };
    }, [room, onDisconnect, setTwilioError]);
}
