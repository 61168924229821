import React from "react";
import { useMessageGetter } from "react-message-context";

import { isSupported } from "twilio-video";

import { ErrorPage } from "../ErrorPage";

interface Props {
    children: React.ReactNode;
}
export const IsSupportedBrowser: React.FC<Props> = ({ children }) => {
    const messages = useMessageGetter("browserUnsupportedError");
    if (isSupported) {
        return <>{children}</>;
    }
    return <ErrorPage title={messages("title")} message={messages("message")} />;
};
