import React from "react";

import styled from "styled-components";
import clsx from "clsx";

import useIsUserActive from "./useIsUserActive";
import { LeaveButton } from "./LeaveButton";
import { ToggleAudioButton } from "./ToggleAudioButton";
import { ToggleVideoButton } from "./ToggleVideoButton";
import { FlipCameraButton } from "./FlipCameraButton";

import "./controls.scss";
import { useAppState } from "../../state";
import { GoLiveButton } from "./GoLiveButton";
import { Meeting } from "../../domain/meeting";
import { CompleteButton } from "./CompleteButton";
import { isMobile } from "../../lib/utils";
import { isBefore, parseISO } from "date-fns";

const Container = styled("div")({
    display: "flex",
    position: "absolute",
    bottom: "50px",
    right: "50%",
    transform: "translate(50%, 30px)",
    zIndex: 2,
    transition: "opacity 1.2s, transform 1.2s, visibility 0s 1.2s",
    opacity: 0,
    visibility: "hidden",
    maxWidth: "100vw",
    flexWrap: "wrap",
    "&.showControls, &:hover": {
        transition: "opacity 0.6s, transform 0.6s, visibility 0s",
        opacity: 1,
        visibility: "visible",
        transform: "translate(50%, 0px)",
    },
});

interface Props {
    meeting: Meeting;
}

export const Controls: React.FC<Props> = ({ meeting }) => {
    const isUserActive = useIsUserActive();
    const {
        session: { role, startsAt },
    } = useAppState();
    const startIsInThePast = startsAt != null ? isBefore(parseISO(startsAt), new Date()) : true;
    return (
        <Container className={clsx({ showControls: isUserActive })}>
            {role === "host" && [
                ...(meeting.state === "demo"
                    ? [<GoLiveButton isMobile={isMobile} key="go-live" meetingId={meeting.meetingId} />]
                    : []),
                <ToggleAudioButton isMobile={isMobile} key="audio" />,
                <ToggleVideoButton isMobile={isMobile} key="video" />,
                <FlipCameraButton isMobile={isMobile} key="flip-camera" />,
            ]}
            <LeaveButton isMobile={isMobile} />
            {role === "host" && meeting.state === "live" && startIsInThePast && (
                <CompleteButton isMobile={isMobile} meetingId={meeting.meetingId} />
            )}
        </Container>
    );
};
