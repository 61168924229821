import React, { useCallback, useRef } from "react";

import { Button } from "@op/opux";
import { IconVideocamOff, IconVideocamOn } from "@op/opux-icons/control";
import { useMessageGetter } from "react-message-context";

import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle";
import { useHasVideoInputDevices } from "../../../hooks/deviceHooks";

export const ToggleVideoButton: React.FC<{ isMobile?: boolean }> = ({ isMobile }) => {
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const lastClickTimeRef = useRef(0);
    const hasVideoDevices = useHasVideoInputDevices();
    const labels = useMessageGetter("actions");

    const toggleVideo = useCallback(() => {
        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now();
            toggleVideoEnabled();
        }
    }, [toggleVideoEnabled]);

    return (
        <Button
            size={isMobile ? "default" : "large"}
            minified
            disabled={!hasVideoDevices}
            icon={isVideoEnabled ? IconVideocamOn : IconVideocamOff}
            onClick={toggleVideo}
            iconLabel={labels("toggleVideo")}
            iconProps={{ type: "default" }}
            className="control"
        />
    );
};
