import React, { createContext, ReactNode, useState } from "react";

export interface TermsOfServiceState {
    /**
     * Should the modal be open? When this set to `false`, the user has "accepted" the terms
     */
    open: boolean;
    /**
     * Set the terms of sercice modal open.
     *
     * @param open Open
     */
    setOpen(open: boolean): void;
    /**
     * Callback function to invoke when modal closed
     */
    onCloseHandler: null | (() => void);
    /**
     * Set the callback function
     */
    setOnCloseHandler: React.Dispatch<React.SetStateAction<null | (() => void)>>;
}

export const TermsOfServiceContext = createContext<TermsOfServiceState>(null!);

interface Props {
    children: ReactNode;
}

export function TermsOfServiceProvider(props: Props) {
    const [open, setOpen] = useState(true);
    const [onCloseHandler, setOnCloseHandler] = useState<null | (() => void)>(null);
    return (
        <TermsOfServiceContext.Provider
            value={{
                open,
                setOpen,
                onCloseHandler,
                setOnCloseHandler,
            }}
        >
            {props.children}
        </TermsOfServiceContext.Provider>
    );
}
