import React, { useCallback } from "react";

import { Button } from "@op/opux";
import { Modal } from "@op/opux-modal";
import { Message, useMessageGetter } from "react-message-context";

import useTermsOfServiceContext from "../../../../hooks/useTermsOfServiceContext";

export const TermsOfService: React.FC<{ role: "host" | "viewer" }> = ({ role }) => {
    const { open, setOpen, onCloseHandler } = useTermsOfServiceContext();
    const messages = useMessageGetter("termsOfService");

    const close = useCallback(() => {
        setOpen(false);
        if (onCloseHandler) {
            onCloseHandler();
        }
    }, [setOpen, onCloseHandler]);

    return (
        <Modal
            appElement={document.getElementById("root") || undefined}
            title={messages("title")}
            isOpen={open && role === "viewer"}
            footer={
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" }}>
                    <Button onClick={close} compact>
                        <Message id="actions.close" />
                    </Button>
                </div>
            }
            onUpdate={(state) => (state.open ? setOpen(state.open) : close())}
        >
            <p>{messages("disclaimer1")}</p>
            <br />
            <p>{messages("disclaimer2")}</p>
        </Modal>
    );
};
