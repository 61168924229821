import useParticipants from "../useParticipants";
import { useState, useEffect } from "react";
import { RemoteParticipant } from "twilio-video";
import { parseTwilioIdentity } from "../../lib/utils";

export function useHost() {
    const participants = useParticipants();
    const [host, setHost] = useState<RemoteParticipant | null>(null);

    useEffect(() => {
        const found = participants.find((p) => {
            const identity = parseTwilioIdentity(p.identity);
            return identity && identity.role === "host";
        });
        setHost(found || null);
    }, [setHost, participants]);

    return host;
}
