import React, { createContext } from "react";
import { ChatMessage } from "../../domain/chat-message";
import { useChat } from "./useChat";
import { SQSTopic } from "../../domain";
import { useSubscriptions } from "./useSubscriptions";
import { APIClient, GetMessagesResponse } from "../../api/api-client";
import { useWebSocket } from "./useWebSocket";
import { usePing } from "./usePing";

export interface WebSocketState {
    /**
     * Chat messages
     */
    messages: ChatMessage[];
    /**
     * WebSocket connection state
     */
    connectionState: "disconnected" | "connected" | "reconnecting";
    /**
     * Topic subscriptions
     */
    subscriptions: SQSTopic[];
    /**
     * Subscribe to topics
     *
     * @param topics Topics to subscribe
     */
    subscribe(...topics: SQSTopic[]): void;
    /**
     * WebSocket instance
     */
    ws: WebSocket | null;
    /**
     * Get messages.
     *
     * @param meetingId Meeting ID
     */
    getMessages(meetingId: string): Promise<GetMessagesResponse>;
    /**
     * Are all messages fetched from the history?
     */
    allFetched: boolean;
    /**
     * Is page refresh needed (due to WebSocket connection issues)?
     */
    pageRefreshNeeded: boolean;
    /**
     * Reset chat history query
     */
    resetQuery(): void;
    /**
     * Has the user been kicked out?
     */
    kickedOut: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const WebSocketContext = createContext<WebSocketState>(null!);

interface WebSocketProviderProps {
    onError?: (error: Error) => void;
    onClose?: () => void;
    meetingId: string;
    identity: string;
}

const apiClient = new APIClient();

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
    onClose,
    onError,
    children,
    meetingId,
    identity,
}) => {
    const { ws, setState: setConnectionState, state: connectionState, pageRefreshNeeded } = useWebSocket({
        meetingId: meetingId,
        onClose,
        onError,
    });
    usePing(ws, setConnectionState);

    const { messages, getMessages, allFetched, resetQuery, kickedOut } = useChat(ws, apiClient, identity);
    const { subscriptions, subscribe } = useSubscriptions(ws);

    const state: WebSocketState = {
        connectionState,
        messages,
        subscriptions,
        subscribe,
        ws,
        getMessages,
        allFetched,
        pageRefreshNeeded,
        resetQuery,
        kickedOut,
    };
    return <WebSocketContext.Provider value={state}>{children}</WebSocketContext.Provider>;
};
