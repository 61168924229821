import { useState, useEffect } from "react";

/**
 * Subscribe to viewport changes.
 */
export function useViewport() {
    const [width, setWidth] = useState(window.innerWidth * (window.visualViewport?.scale || 1));
    const [height, setHeight] = useState(window.innerHeight * (window.visualViewport?.scale || 1));

    useEffect(() => {
        const resizeWidth = () => {
            setWidth(window.innerWidth * (window.visualViewport?.scale || 1));
            setHeight(window.innerHeight * (window.visualViewport?.scale || 1));
        };
        window.addEventListener("resize", resizeWidth);
        return () => window.removeEventListener("resize", resizeWidth);
    });

    return { width, height };
}
