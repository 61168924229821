import React, { useEffect } from "react";

export function useClickAwayListener(ref: React.RefObject<HTMLElement>, onClickAway: () => void) {
    useEffect(() => {
        function handleClickAway(event: MouseEvent) {
            if (ref.current && event.target && !ref.current.contains(event.target as Node)) {
                onClickAway();
            }
        }
        document.addEventListener("mousedown", handleClickAway);
        return () => {
            document.removeEventListener("mousedown", handleClickAway);
        };
    }, [ref, onClickAway]);
}
