import FullScreenExitIcon from "./FullScreenExit";
import FullScreenIcon from "./FullScreen";
import RoomsCircledIcon from "./RoomsCircled";
import BubbleCircledIcon from "./BubbleCircled";
import InfoCircledIcon from "./InfoCircled";

export const IconFullScreen = FullScreenIcon;

export const IconFullScreenExit = FullScreenExitIcon;

export const IconRoomsCircled = RoomsCircledIcon;

export const IconBubbleCircled = BubbleCircledIcon;

export const IconInfoCircled = InfoCircledIcon;
