import { CreateLocalTrackOptions } from "twilio-video";

export const header = {
    padding: 16,
    logo: {
        small: 32,
        large: 48,
    },
};

export const breakpoints = {
    smMax: 768,
};

export const defaultLocalTrackOptions: CreateLocalTrackOptions = {
    height: 720,
    width: 1280,
    frameRate: 24,
    facingMode: "environment",
};
