import React from "react";

import { useHost } from "../../hooks/useHost";
import { ParticipantTracks } from "./ParticipantTracks";
import { ErrorPage } from "../ErrorPage";
import { useMessageGetter } from "react-message-context";

export const MainParticipant: React.FC = () => {
    const host = useHost();
    const messages = useMessageGetter("player");

    return host ? <ParticipantTracks participant={host} /> : <ErrorPage title={messages("hostDisconnected")} />;
};
