import { useCallback, useState } from "react";

import Video, { LocalVideoTrack, LocalAudioTrack, CreateLocalTrackOptions } from "twilio-video";
import { defaultLocalTrackOptions } from "../../../globals";
import { useAudioInputDevices, useVideoInputDevices } from "../../../hooks/deviceHooks";

export default function useLocalTracks() {
    const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
    const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
    const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);

    const localAudioDevices = useAudioInputDevices();
    const localVideoDevices = useVideoInputDevices();

    const hasAudio = localAudioDevices.length > 0;
    const hasVideo = localVideoDevices.length > 0;

    const getLocalAudioTrack = useCallback((deviceId?: string) => {
        const options: CreateLocalTrackOptions = {};

        if (deviceId) {
            options.deviceId = { exact: deviceId };
        }

        return Video.createLocalAudioTrack(options).then((newTrack) => {
            setAudioTrack(newTrack);
            return newTrack;
        });
    }, []);

    const getLocalVideoTrack = useCallback((newOptions?: CreateLocalTrackOptions) => {
        const options: CreateLocalTrackOptions = {
            ...defaultLocalTrackOptions,
            name: `camera-${Date.now()}`,
            ...newOptions,
        };

        return Video.createLocalVideoTrack(options).then((newTrack) => {
            setVideoTrack(newTrack);
            return newTrack;
        });
    }, []);

    const removeLocalVideoTrack = useCallback(() => {
        if (videoTrack) {
            videoTrack.stop();
            setVideoTrack(undefined);
        }
    }, [videoTrack]);

    const removeLocalAudioTrack = useCallback(() => {
        if (audioTrack) {
            audioTrack.stop();
            setAudioTrack(undefined);
        }
    }, [audioTrack]);

    const getAudioAndVideoTracks = useCallback(() => {
        if (!hasAudio && !hasVideo) return Promise.resolve();
        if (isAcquiringLocalTracks || audioTrack || videoTrack) return Promise.resolve();
        setIsAcquiringLocalTracks(true);

        const localTrackConstraints = {
            video: hasVideo && {
                ...defaultLocalTrackOptions,
                name: `camera-${Date.now()}`,
            },
            audio: hasAudio,
        };

        return Video.createLocalTracks(localTrackConstraints)
            .then((tracks) => {
                const videoTrack = tracks.find((track) => track.kind === "video");
                const audioTrack = tracks.find((track) => track.kind === "audio");
                if (videoTrack) {
                    setVideoTrack(videoTrack as LocalVideoTrack);
                }
                if (audioTrack) {
                    setAudioTrack(audioTrack as LocalAudioTrack);
                }
            })
            .finally(() => setIsAcquiringLocalTracks(false));
    }, [hasAudio, hasVideo, audioTrack, videoTrack, isAcquiringLocalTracks]);

    const localTracks = [audioTrack, videoTrack].filter((track) => typeof track !== "undefined") as (
        | LocalAudioTrack
        | LocalVideoTrack
    )[];

    return {
        localTracks,
        getLocalVideoTrack,
        getLocalAudioTrack,
        removeLocalVideoTrack,
        removeLocalAudioTrack,
        getAudioAndVideoTracks,
    };
}
