import React, { useCallback, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";
import { Button, Badge } from "@op/opux";
import { IconChat } from "@op/opux-icons/action";

import { Messages } from "./Messages";
import useWebSocketContext from "../../hooks/useWebSocketContext";
import { useMessageGetter } from "react-message-context";
import { useUnreadMessages } from "./useUnreadMessages";
import { useClickAwayListener } from "../../hooks/useClickAwayListener";

import "./styles.scss";

const Aside = styled.aside`
    position: fixed;
    right: 0.8rem;
    display: flex;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 3;
`;

const ButtonContainer = styled.div`
    position: fixed;
    bottom: 1.8rem;
    right: 2.4rem;
    z-index: 2;
`;

const BadgeContainer = styled.div`
    display: inline-block;
    transform: translate(50%, -125%);
`;

export const Chat: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [lastOpen, setLastOpen] = useState<string | null>(null);
    const toggleOpen = useCallback(() => setOpen((prev) => !prev), [setOpen]);
    const { meetingId } = useParams<{ meetingId: string }>();
    const asideRef = useRef<HTMLElement | null>(null);
    const btnRef = useRef<HTMLDivElement | null>(null);
    const {
        subscribe,
        subscriptions,
        getMessages,
        messages: chatMessages,
        resetQuery,
        connectionState,
    } = useWebSocketContext();
    const [messageHistoryError, setMessageHistoryError] = useState(false);
    const messages = useMessageGetter("chat");
    const { count } = useUnreadMessages(chatMessages, lastOpen || getPersistedLastOpened());
    const [resetFlag, setResetFlag] = useState(false);
    const [childModalOpen, setChildModalOpen] = useState(false);

    // Toggle open only when there is no child modal open
    useClickAwayListener(asideRef, () => !childModalOpen && toggleOpen());

    React.useEffect(() => {
        if (connectionState === "connected" && resetFlag) {
            setResetFlag(false);
            resetQuery();
        } else if (connectionState === "reconnecting") {
            setResetFlag(true);
        }
    }, [connectionState, resetFlag, setResetFlag, resetQuery]);

    /**
     * Subscribe to chat when mounted.
     */
    React.useEffect(() => {
        if (connectionState === "connected" && !subscriptions.includes("chat")) {
            subscribe("chat", "meeting");
        }
        return () => {
            if (connectionState === "connected" && subscriptions.includes("chat")) {
                subscribe("meeting");
            }
        };
    }, [subscriptions, subscribe, connectionState]);

    /**
     * Here we fetch the message history. This implementation will
     * actually fetch the whole history if just possible - getMessages
     * function is a callback that will change if next page is available.
     */
    React.useEffect(() => {
        // Get messages when connected
        if (connectionState === "connected") {
            getMessages(meetingId).catch((err) => {
                console.error(err);
                setMessageHistoryError(true);
            });
        }
    }, [meetingId, getMessages, setMessageHistoryError, connectionState]);

    React.useEffect(() => {
        // Set the timestamp when closing
        if (!open) {
            const datetime = new Date().toISOString();
            setLastOpen((prev) => (prev === null ? getPersistedLastOpened() : datetime));
            persistLastOpened(datetime);
        }
    }, [open, setLastOpen]);

    return (
        <>
            {connectionState === "connected" && (
                <CSSTransition nodeRef={asideRef} in={open} timeout={200} classNames="fade-in" unmountOnExit>
                    <Aside ref={asideRef}>
                        <Messages
                            onModalStateChange={setChildModalOpen}
                            onClose={toggleOpen}
                            meetingId={meetingId}
                            messageHistoryError={messageHistoryError}
                        />
                    </Aside>
                </CSSTransition>
            )}
            <CSSTransition nodeRef={btnRef} in={!open} timeout={200} classNames="fade-in" unmountOnExit>
                <ButtonContainer ref={btnRef}>
                    {count > 0 && (
                        <BadgeContainer>
                            <Badge badgeContent={count} badgeStyle={{ backgroundColor: "#ff7e1f", color: "white" }} />
                        </BadgeContainer>
                    )}
                    <Button
                        minified
                        icon={IconChat}
                        loading={connectionState === "reconnecting"}
                        disabled={connectionState === "disconnected"}
                        size="hero"
                        iconProps={{ type: "default" }}
                        iconLabel={messages("title")}
                        onClick={toggleOpen}
                    />
                </ButtonContainer>
            </CSSTransition>
        </>
    );
};

const LAST_OPENED_SESSION_STORE_KEY = "chat_last_opened";
const INITIAL_LAST_OPEN = "2020-01-01T00:00:00.000Z";

function getPersistedLastOpened(): string {
    const value = sessionStorage.getItem(LAST_OPENED_SESSION_STORE_KEY);
    if (value) {
        if (!isNaN(Date.parse(value))) {
            return value;
        }
    }
    return INITIAL_LAST_OPEN;
}

function persistLastOpened(timestamp: string) {
    setImmediate(() => {
        sessionStorage.setItem(LAST_OPENED_SESSION_STORE_KEY, timestamp);
    });
}
