import { AppUserRole } from "../domain";

/**
 * Construct a querystring.
 *
 * @param params Parameters to stringify
 */
export const querystring = (params: Record<string, string | number | undefined>): string =>
    Object.keys(params)
        .filter((key) => typeof params[key] !== "undefined")
        .map((key) => `${key}=${encodeURIComponent(params[key] as string | number)}`)
        .join("&");

/**
 * This function ensures that the user has granted the browser permission to use audio and video
 * devices. If permission has not been granted, it will cause the browser to ask for permission
 * for audio and video at the same time (as opposed to separate requests).
 */
export function ensureMediaPermissions() {
    return navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => devices.every((device) => !(device.deviceId && device.label)))
        .then((shouldAskForMediaPermissions) => {
            if (shouldAskForMediaPermissions) {
                return navigator.mediaDevices
                    .getUserMedia({ audio: true, video: true })
                    .then((mediaStream) => mediaStream.getTracks().forEach((track) => track.stop()));
            }
        });
}

/**
 * Is the device mobile?
 */
export const isMobile = (() => {
    if (typeof navigator === "undefined" || typeof navigator.userAgent !== "string") {
        return false;
    }
    return /Mobile/.test(navigator.userAgent);
})();

export type FullscreenSupport = "full" | "webkit";

/**
 * Is fullscreen supported?
 */
export const fullscreenSupport: FullscreenSupport | null = ((): FullscreenSupport | null => {
    if ("fullscreenElement" in document) {
        return "full";
    }
    if ("webkitFullscreenElement" in document) {
        return "webkit";
    }
    return null;
})();

/**
 * Throttle function execution by milliseconds.
 *
 * @param fn Function to be throttled
 * @param ms Milliseconds
 */
export const throttle = <T = void>(fn: () => T, ms: number) => {
    let lastInvoke = 0;
    let lastVal: T | null = null;
    let scheduled: number | null = null;

    // Provide a way to cancel the throttled execution in case
    // the user is performing side effects in it (e.g. component
    // unmounting, we don't want to update state anymore)
    const cancel = () => {
        if (scheduled !== null) {
            clearTimeout(scheduled);
            scheduled = null;
        }
    };

    const throttled = (): T => {
        const diff = Date.now() - lastInvoke;
        if (diff >= ms) {
            lastVal = fn();
            lastInvoke = Date.now();
            return lastVal;
        } else {
            if (scheduled === null) {
                scheduled = setTimeout(() => {
                    lastVal = fn();
                    lastInvoke = Date.now();
                    scheduled = null;
                }, ms - diff);
            }
            return lastVal as T;
        }
    };

    return { throttled, cancel };
};

/**
 * Format the difference into `hh:mm:ss` -format.
 *
 * @param difference Time difference in milliseconds
 */
export function formatDifference(difference: number) {
    let ss = Math.floor(difference / 1000);
    let hh = 0;
    let mm = 0;
    if (ss > 3599) {
        hh = Math.floor(ss / 3600);
        ss = ss % 3600;
    }
    if (ss > 59) {
        mm = Math.floor(ss / 60);
        ss = ss % 60;
    }
    return `${hh < 10 ? "0" + hh : hh}:${mm < 10 ? "0" + mm : mm}:${ss < 10 ? "0" + ss : ss}`;
}

const ROLES: AppUserRole[] = ["viewer", "host"];
/**
 * Parse twilio identity.
 *
 * @param identity Identity property from Twilii participant
 */
export function parseTwilioIdentity(identity: string) {
    if (!identity) {
        return null;
    }
    const [role, ...parts] = identity.split(":");
    const name = parts.join(":"); // parts should contain only one element, but just in case
    if (!role || !name || !ROLES.includes(role as AppUserRole)) {
        return null;
    }
    return {
        role: role as AppUserRole,
        name,
    };
}

// Counter for room participants
let counter = 0;

/**
 * Cerate a readable (human-friendly) unique ID for chat message author.
 */
export function createReadableId(): string {
    return `${++counter}`; // Just a number
}
