import { useState, useEffect, useCallback } from "react";

import { ChatMessage } from "../../../domain/chat-message";
import { SQSChatMessage, SQSParticipantMessage } from "../../../domain";
import { APIClient, GetMessagesQuery } from "../../../api/api-client";
import { createReadableId, parseTwilioIdentity } from "../../../lib/utils";

const cache: Record<string, string> = {};
/**
 * Memoization function. Cache values and generate new one if we don't hit cache.
 *
 * @param author The message author
 */
const memoizeAuthorName = (author: string) => {
    const cached = cache[author];
    if (!cached) {
        const created = createReadableId();
        cache[author] = created;
        return created;
    }
    return cached;
};

export function useChat(ws: WebSocket | null, client: APIClient, identity: string) {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [query, setQuery] = useState<Partial<GetMessagesQuery>>({});
    const [allFetched, setAllFetched] = useState(false);
    const [kickedOut, setKickedOut] = useState(false);

    /**
     * This can be used when we need to fetch chat messages again
     */
    const resetQuery = useCallback(() => setQuery({}), [setQuery]);

    const getMessages = useCallback(
        (meetingId: string) => {
            return client.getMessages(meetingId, query).then((res) => {
                const { messages, nextKey } = res;
                if (nextKey) {
                    setQuery({
                        ...query,
                        key: nextKey,
                    });
                } else {
                    setAllFetched(true);
                }
                setMessages((prev) => [
                    ...messages
                        .filter((m) => !prev.some((pm) => pm.createdAt === m.createdAt))
                        .map<ChatMessage>((m) => ({
                            ...m,
                            // Memoization is important here! Otherwise messages from the same author will have different author name.
                            generatedAuthorId: memoizeAuthorName(m.author),
                            authorRole: parseTwilioIdentity(m.author)?.role || "unknown",
                        })),
                    ...prev,
                ]);
                return res;
            });
        },
        [query, setMessages, setAllFetched, client],
    );

    useEffect(() => {
        if (ws) {
            const onMessage = (event: MessageEvent) => {
                let data: SQSChatMessage | SQSParticipantMessage;
                try {
                    data = JSON.parse(event.data);
                } catch (e) {
                    console.warn(e);
                    return;
                }

                if (data && data.topic === "chat") {
                    switch (data.type) {
                        case "message":
                            if (data.message) {
                                const msg = (data as SQSChatMessage).message;
                                setMessages((prev) => [
                                    ...prev,
                                    {
                                        ...msg,
                                        generatedAuthorId: memoizeAuthorName(msg.author),
                                        authorRole: parseTwilioIdentity(msg.author)?.role || "unknown",
                                    },
                                ]);
                            }
                            break;
                        case "participant-removed":
                            const author = data.identity;
                            setMessages((prev) => prev.filter((item) => item.author !== author));
                            if (author === identity) {
                                // It's me who has been kicked out
                                setKickedOut(true);
                            }
                            break;
                    }
                }
            };
            ws.addEventListener("message", onMessage);
            return () => ws.removeEventListener("message", onMessage);
        }
    });

    return { messages, getMessages, allFetched, resetQuery, kickedOut };
}
