import { useEffect, useRef, useState } from "react";

import { throttle } from "../../../lib/utils";

export default function useIsUserActive() {
    const [isUserActive, setIsUserActive] = useState(true);
    const timeoutIDRef = useRef(0);

    useEffect(() => {
        const { throttled: handleUserActivity, cancel } = throttle(() => {
            setIsUserActive(true);
            clearTimeout(timeoutIDRef.current);
            const timeoutID = window.setTimeout(() => setIsUserActive(false), 5000);
            timeoutIDRef.current = timeoutID;
        }, 500);

        handleUserActivity();

        window.addEventListener("mousemove", handleUserActivity);
        window.addEventListener("click", handleUserActivity);
        window.addEventListener("keydown", handleUserActivity);
        return () => {
            cancel(); // Prevent throttled execution to start
            window.removeEventListener("mousemove", handleUserActivity);
            window.removeEventListener("click", handleUserActivity);
            window.removeEventListener("keydown", handleUserActivity);
            clearTimeout(timeoutIDRef.current);
        };
    }, []);

    return isUserActive;
}
