import React from "react";

import { Loading, Row } from "@op/opux";

import { Page } from "../Page";

export const LoadingPage: React.FC = () => (
    <Page fullHeight>
        <Row style={{ flexGrow: 1 }} center middle>
            <div>
                <Loading />
            </div>
        </Row>
    </Page>
);
