import React, { useState } from "react";

import { Action, Button } from "@op/opux";
import { Message, useMessageGetter } from "react-message-context";
import styled from "styled-components";
import { parseISO, format, isToday } from "date-fns";

import { Meeting } from "../../../../domain/meeting";
import { ReactComponent as WelcomeIllustration } from "../../../../assets/img/Welcome_user_illustration.svg";
import { Session } from "../../../../domain";
import { RealtorModal } from "../../../RealtorModal";
import { IconBubbleCircled, IconInfoCircled, IconRoomsCircled } from "../../../icons";
import useDateFnsLocale from "../../../../hooks/useDateFnsLocale";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
    text-align: center;
`;

const ImgContainer = styled.div`
    padding: 0 1.6rem 2.4rem 1.6rem;
    flex: 1;
    width: 70%;
    max-width: 400px;
`;

const BtnContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const BtnWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 1.6rem;
`;

const BtnLabel = styled.span`
    margin-top: 0.4rem;
`;

const TxtContainer = styled.section`
    margin-bottom: 2.4rem;
    & > p {
        margin-top: 0.4rem;
    }
`;

interface ViewerLobbyProps {
    meeting?: Meeting | null;
    session: Session;
    joinPending?: boolean;
    onJoin: () => void;
}

export const ViewerLobby: React.FC<ViewerLobbyProps> = ({ meeting, onJoin, joinPending, session, children }) => {
    const messages = useMessageGetter("lobby.viewer");
    const misc = useMessageGetter("misc");
    const [open, setOpen] = useState(false);
    const ref = React.useRef<any>();

    const onClose = () => setOpen(false);
    const onOpen = () => setOpen(true);

    // Scroll into the join button when state goes to "live"
    React.useEffect(() => {
        if (meeting?.state === "live") {
            ref.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [meeting]);

    return (
        <Container>
            <ImgContainer>
                <WelcomeIllustration />
            </ImgContainer>
            <TxtContainer>
                <h1>{messages("title")}</h1>
                <p>
                    {messages("message1")} {messages("message2")}
                </p>
                <p>
                    {messages("message31")}{" "}
                    <Action target="_blank" href="https://op-koti.fi/evasteet" inline>
                        {messages("here")}
                    </Action>
                    . {messages("message32")}{" "}
                    <Action target="_blank" href="https://op-koti.fi/henkilotietojen-kasittely" inline>
                        {messages("here")}
                    </Action>
                    .
                </p>
            </TxtContainer>
            {children}
            {meeting?.state === "live" ? (
                <BtnContainer style={{ marginBottom: "2.4rem" }}>
                    <Button primary loading={joinPending} nonInteractive={joinPending} onClick={() => onJoin()}>
                        <Message id="actions.join" />
                        <div ref={ref} />
                    </Button>
                </BtnContainer>
            ) : (
                <MeetingStartsComponent startTime={session.startsAt ? parseISO(session.startsAt) : null} />
            )}

            <BtnContainer>
                <BtnWrapper>
                    <Button
                        icon={IconInfoCircled}
                        minified
                        ghost
                        iconProps={{
                            size: "small",
                        }}
                        iconLabel={misc("info")}
                        href={session.info1 || undefined}
                        target="_blank"
                        disabled={!session.info1}
                    />
                    <BtnLabel>{misc("info")}</BtnLabel>
                </BtnWrapper>
                <BtnWrapper>
                    <Button
                        icon={IconRoomsCircled}
                        minified
                        ghost
                        iconLabel={misc("floorPlan")}
                        href={session.info2 || undefined}
                        target="_blank"
                        disabled={!session.info2}
                        iconProps={{
                            size: "small",
                        }}
                    />
                    <BtnLabel>{misc("floorPlan")}</BtnLabel>
                </BtnWrapper>
                <BtnWrapper>
                    <Button
                        icon={IconBubbleCircled}
                        minified
                        ghost
                        iconProps={{
                            size: "small",
                        }}
                        iconLabel={misc("realtor")}
                        onClick={onOpen}
                    />
                    <BtnLabel>{misc("realtor")}</BtnLabel>
                </BtnWrapper>
            </BtnContainer>
            <RealtorModal session={session} open={open} onClose={onClose} />
        </Container>
    );
};

const MeetingStartsComponent: React.FC<{ startTime: Date | null }> = ({ startTime }) => {
    const messages = useMessageGetter("lobby.viewer");

    if (!startTime) {
        return null;
    }

    return (
        <div style={{ marginBottom: "2.4rem", display: "flex", alignItems: "center", flexDirection: "column" }}>
            <p style={{ textTransform: "uppercase" }}>{messages("startsAt")}</p>
            <FormattedDate startTime={startTime} />
        </div>
    );
};

const FormattedDate: React.FC<{ startTime: Date }> = ({ startTime }) => {
    // TODO: Localize 'klo' when implementing more translations
    const formatString = isToday(startTime) ? "'klo' p" : "P 'klo' p";
    const locale = useDateFnsLocale();

    return <p style={{ fontSize: "4.6rem" }}>{format(startTime, formatString, { locale })}</p>;
};
