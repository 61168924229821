import React from "react";

import { LocalVideoTrack } from "twilio-video";

import VideoTrack from "../VideoTrack";
import useVideoContext from "../../hooks/useVideoContext";
import { ErrorPage } from "../ErrorPage";
import { useMessageGetter } from "react-message-context";

export function LocalParticipant() {
    const { localTracks } = useVideoContext();
    const messages = useMessageGetter("player");

    const videoTrack = localTracks.find((track) => track.name.includes("camera")) as LocalVideoTrack;

    return videoTrack ? (
        <VideoTrack track={videoTrack} isLocal />
    ) : (
        <ErrorPage icon={null} title={messages("videoDisabled")} />
    );
}
