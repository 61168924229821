import React, { useState, useEffect, useCallback } from "react";

import { Alert, Col, Row } from "@op/opux";
import axios, { CancelToken } from "axios";
import { useMessageGetter } from "react-message-context";
import { useParams } from "react-router-dom";
import { AxiosError } from "axios";

import { useAppState } from "../../../state";
import { Page } from "../../Page";
import { ErrorPage } from "../../ErrorPage";
import { LoadingPage } from "../../LoadingPage";
import useVideoContext from "../../../hooks/useVideoContext";
import { HostLobby } from "./HostLobby";
import { ViewerLobby } from "./ViewerLobby";
import useWebSocketContext from "../../../hooks/useWebSocketContext";
import { EndedLobby } from "./EndedLobby";
import { TwilioError } from "twilio-video";

type GetMeetingError = "not-found" | "internal-server-error" | "bad-request";

export const Lobby: React.FC = () => {
    const { meetingId } = useParams<{ meetingId: string }>();
    const { session, getMeeting, meeting, getMeetingToken, updateMeeting, setTwilioError } = useAppState();
    const { connect, getAudioAndVideoTracks } = useVideoContext();
    const { kickedOut } = useWebSocketContext();
    const [error, setError] = useState<null | GetMeetingError>(null);
    const [pending, setPending] = useState(true);
    const [joiningError, setJoiningError] = useState(false);
    const errorMessages = useMessageGetter("lobby.errors");
    const kickedOutMessages = useMessageGetter("errors.kickedOut");
    // Realtor
    const [livePending, setLivePending] = useState(false);
    const [demoPending, setDemoPending] = useState(false);
    // Customer
    const [joinPending, setJoinPending] = useState(false);

    const onJoinRoom = (method: "demo" | "live" | "join") => {
        if (livePending || demoPending || joinPending) {
            return;
        }
        const stateUpdater = method === "demo" ? setDemoPending : method === "join" ? setJoinPending : setLivePending;
        stateUpdater(true);

        Promise.resolve()
            .then(() => {
                if (session.role === "host") {
                    return getAudioAndVideoTracks().then(() => {
                        if (
                            !(
                                method === "join" ||
                                meeting?.state === "live" ||
                                (meeting?.state === "demo" && method === "demo")
                            )
                        ) {
                            return updateMeeting(meetingId, method);
                        }
                    });
                }
            })
            .then(() => getMeetingToken(meetingId))
            .then((token) => {
                if (!token) {
                    throw new Error("Could not obtain token");
                }
                return connect(token, session.role);
            })
            .catch((err: AxiosError | TwilioError | DOMException | Error) => {
                console.error(err);
                // Can be either TwilioError or native DOMException. DOMException usually has property code = 0
                if ("code" in err) {
                    setTwilioError(err as TwilioError);
                } else {
                    setJoiningError(true);
                }
                stateUpdater(false);
            });
    };

    const onDemo = () => onJoinRoom("demo");
    const onLive = () => onJoinRoom("live");
    const onJoin = () => onJoinRoom("join");

    const fetchMeeting = useCallback(
        (cancelToken?: CancelToken) => {
            setPending(true);
            getMeeting(meetingId, { cancelToken })
                .then((meeting) => {
                    if (!meeting) {
                        setError("not-found");
                    }
                    setPending(false);
                })
                .catch((err) => {
                    console.error(err);
                    // TODO: We need to show user error if status is 500
                    setPending(false);
                });
        },
        [meetingId, getMeeting],
    );

    useEffect(() => {
        const source = axios.CancelToken.source();
        fetchMeeting(source.token);
        return () => {
            source.cancel("Component will unmount");
        };
    }, [fetchMeeting]);

    if (pending) {
        return <LoadingPage />;
    }

    if (error) {
        return <ErrorPage title={errorMessages("notFound.title")} message={errorMessages("notFound.message")} />;
    }

    if (kickedOut) {
        return <ErrorPage title={kickedOutMessages("title")} message={kickedOutMessages("legal")} />;
    }

    return (
        <Page fullHeight noPadding>
            <Row style={{ flexGrow: 1 }} center middle={session.role === "host" ? true : undefined}>
                <Col xs={12} sm={9} md={8} lg={6} xl={4}>
                    {meeting?.state === "completed" ? (
                        <EndedLobby session={session} />
                    ) : session.role === "host" ? (
                        <HostLobby
                            meeting={meeting}
                            demoPending={demoPending}
                            livePending={livePending}
                            onDemo={onDemo}
                            onLive={onLive}
                        >
                            {joiningError && (
                                <Alert embedded error>
                                    {errorMessages("joiningFailed.message")}
                                </Alert>
                            )}
                        </HostLobby>
                    ) : (
                        <ViewerLobby meeting={meeting} session={session} onJoin={onJoin} joinPending={joinPending}>
                            {joiningError && <Alert error>{errorMessages("joiningFailed.message")}</Alert>}
                        </ViewerLobby>
                    )}
                </Col>
            </Row>
        </Page>
    );
};
