import { useState, useEffect } from "react";

import { ChatMessageBase } from "../../../domain/chat-message";

/**
 * Hook for getting the count of unread messages.
 *
 * @param messages The chat messages
 * @param lastRead Last read timestamp in ISO 8601 format e.g. `new Date().toISOString()`
 */
export function useUnreadMessages(messages: ChatMessageBase[], lastRead: string) {
    const [count, setCount] = useState(0);

    // Update unread message count
    useEffect(() => {
        setCount(messages.filter(({ createdAt }) => createdAt > lastRead).length);
    }, [lastRead, setCount, messages]);

    return { count };
}
