import React from "react";

import { ErrorPage } from "../ErrorPage";
import { useMessageGetter } from "react-message-context";

export const NotFoundPage: React.FC = () => {
    const messages = useMessageGetter("errors");

    return <ErrorPage title={messages("pageNotFound")} />;
};
