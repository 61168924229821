import { AxiosError } from "axios";
import { TwilioError } from "twilio-video";

/**
 * Send a Twilio error event to Adobe Analytics.
 *
 * @param error The Twilio error
 */
export function sendTwilioError(error: TwilioError) {
    window.adobeAnalyticsData = {
        ...window.adobeAnalyticsData,
        eVar84: `twilio|${error.code || "0"}|${error.stack}`,
    };
    window._satellite?.track("applicationError");
}

/**
 * Send a component error stack trace to Adobe Analytics.
 *
 * @param error The error that occurred
 */
export function sendComponentError(error: Error) {
    window.adobeAnalyticsData = {
        ...window.adobeAnalyticsData,
        eVar84: `client||${error.stack}`,
    };
    window._satellite?.track("applicationError");
}

const DEFAULT_STATUS_CODE = "N/A";
const DEFAULT_REQUEST_METHOD = "N/A";
const DEFAULT_REQUEST_PATH = "N/A";
const DEFAULT_RESPONSE_BODY = "N/A";

/**
 * Send a Home Alone Service error to Adobe Anlytics.
 *
 * @param error Axios error that has occurred
 */
export function sendHomeAloneError(error: AxiosError) {
    window.adobeAnalyticsData = {
        ...window.adobeAnalyticsData,
        eVar84: `ha|${error.response?.status || DEFAULT_STATUS_CODE}|${
            error.config?.method || DEFAULT_REQUEST_METHOD
        }|${error.config?.url || DEFAULT_REQUEST_PATH}|${error.request?.responseText || DEFAULT_RESPONSE_BODY}`,
    };
    window._satellite?.track("applicationError");
}
