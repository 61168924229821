import React from "react";
import ReactDOM from "react-dom";
import { MessageProvider } from "react-message-context";
import { LocaleProvider } from "@op/opux";

import "@op/opux/base.css";
import "@op/opux/components.css";
import "@op/opux-static/core.css";
import "./index.scss";

import messages from "./locale/fi.messages.json";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import AppStateProvider from "./state";
import { IsSupportedBrowser } from "./components/IsSupportedBrowser";

window.adobeAnalyticsData = {
    eVar80: "Etaesittely",
    // Initialize the "meetingId|role" pair
    eVar83: "N/A|N/A",
};

/**
 * TODO: Using both LocaleProvider and MessageProvider seems fucked up, but not sure how
 * localization is supposed to be done with the final OPUX v3 release.
 */
ReactDOM.render(
    <React.StrictMode>
        <MessageProvider locale="fi" messages={messages}>
            <LocaleProvider locale="fi">
                <AppStateProvider>
                    <IsSupportedBrowser>
                        <App />
                    </IsSupportedBrowser>
                </AppStateProvider>
            </LocaleProvider>
        </MessageProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
