import React, { useCallback, useState } from "react";

import { Button } from "@op/opux";
import { LogoOPHome } from "@op/opux-logo/op-home";
import styled, { CSSObject } from "styled-components";
import { useMessageGetter } from "react-message-context";

import { useAppState } from "../../state";
import { header, breakpoints } from "../../globals";
import { useViewport } from "../../hooks/useViewport";
import { RealtorModal } from "../RealtorModal";
import { IconFullScreen, IconFullScreenExit } from "../icons";
import { fullscreenSupport } from "../../lib/utils";

const shared: CSSObject = {
    padding: header.padding,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    zIndex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
};

const headerElement = styled("header");

const PlayerHeaderContainer = headerElement({
    ...shared,
    background: "linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))",
    // For the fullscreen icon
    "& button > svg > path": {
        fill: "white !important",
    },
});

const HeaderContainer = headerElement({
    ...shared,
    backgroundColor: "white",
    position: "static",
    // boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)",
});

const ServiceContainer = styled.div`
    display: flex;
    flex: 1;
`;

const MeetingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: middle;
    font-size: 2.1rem;
    margin: auto 1.8rem;
`;

const ButtonContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    & > button:not(:last-child) {
        margin-right: 0.9rem;
    }
`;

export const Header: React.FC = () => {
    const { session, playerPage } = useAppState();
    const { width } = useViewport();
    const service = useMessageGetter("service");
    const misc = useMessageGetter("misc");
    const actions = useMessageGetter("actions");
    const [modalOpen, setModalOpen] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);

    const onToggleFullScreen = useCallback(() => {
        // We need to use body to include all modal dialogs into fullscreen
        const root = document.getElementsByTagName("body").item(0);
        if (!fullScreen && !document.fullscreenElement && fullscreenSupport) {
            if (fullscreenSupport === "full") {
                root?.requestFullscreen().catch((e) => console.error("Full screen failed", e));
            }
        } else if (document.exitFullscreen) {
            document.exitFullscreen().catch((e) => console.error("Exit full screen failed", e));
        }
    }, [fullScreen]);

    // Remove full screen if user leaves the player page
    React.useEffect(() => {
        if (!playerPage && fullScreen) {
            onToggleFullScreen();
        }
    }, [playerPage, onToggleFullScreen, fullScreen]);

    // Listen for full screen change events. Safari doesn't emit these.
    React.useEffect(() => {
        const onChange = () => {
            setFullScreen(!!document.fullscreenElement);
        };
        document.addEventListener("fullscreenchange", onChange);
        return () => {
            document.removeEventListener("fullscreenchange", onChange);
        };
    }, [setFullScreen]);

    const onModalOpen = () => setModalOpen(true);
    const onModalClose = () => setModalOpen(false);

    const small = width <= breakpoints.smMax;
    const height = !small ? header.logo.large : header.logo.small;
    const Container = playerPage ? PlayerHeaderContainer : HeaderContainer;

    return (
        <Container>
            <ServiceContainer>
                <LogoOPHome label={service("name")} height={height} onDarkBg={playerPage} />
            </ServiceContainer>
            {session.meetingTitle && !(playerPage && small) && (
                <MeetingContainer style={playerPage ? { color: "#fafafa" } : undefined}>
                    <span>{session.meetingTitle}</span>
                </MeetingContainer>
            )}
            {session.role === "viewer" && (
                <ButtonContainer>
                    {playerPage && (
                        <>
                            {session.info1 && (
                                <CustomBtn
                                    iconClassName="icon-info-circled"
                                    label={misc("info")}
                                    href={session.info1}
                                    target="_blank"
                                    small={small}
                                />
                            )}
                            {session.info2 && (
                                <CustomBtn
                                    iconClassName="icon-house_rooms-circled"
                                    label={misc("floorPlan")}
                                    href={session.info2}
                                    target="_blank"
                                    small={small}
                                />
                            )}
                            {session.hostName && (
                                <CustomBtn
                                    iconClassName="icon-person-circled"
                                    label={misc("realtor")}
                                    onClick={onModalOpen}
                                    small={small}
                                />
                            )}
                        </>
                    )}
                </ButtonContainer>
            )}
            {fullscreenSupport === "full" && session.role === "host" && playerPage && (
                <ButtonContainer>
                    <Button
                        size="large"
                        icon={fullScreen ? IconFullScreenExit : IconFullScreen}
                        minified
                        ghost
                        iconProps={{ onDarkBg: true }}
                        iconLabel={actions(`toggleFullScreen.${fullScreen ? "off" : "on"}`)}
                        onClick={onToggleFullScreen}
                    />
                </ButtonContainer>
            )}
            <RealtorModal session={session} open={modalOpen} onClose={onModalClose} />
        </Container>
    );
};

const BtnElem = styled.button<{ small?: boolean }>`
    margin-left: 1.2rem;
    color: white;
    background: transparent;
    border: none;
    vertical-align: middle;

    align-items: center;
    cursor: pointer;
    display: inline-flex;
    font-family: inherit;
    font-size: 1.9rem;
    font-weight: 500;
    justify-content: center;
    line-height: 2.4rem;
    overflow: hidden;
    padding: 0 0.9rem;
    text-decoration: none;
    white-space: nowrap;

    :focus {
        outline: none;
    }

    :hover {
        color: rgba(255, 255, 255, 0.6);
    }

    & > i {
        margin-left: ${({ small }) => (small ? "0" : "-0.8rem")};
        margin-right: ${({ small }) => (small ? "0" : "0.8rem")};
        font-size: ${({ small }) => (small ? "2.4rem" : "3.2rem")};
    }
`;

interface CustomBtnProps {
    iconClassName: string;
    label: string;
    onClick?: () => void;
    href?: string;
    target?: string;
    small?: boolean;
}

const CustomBtn: React.FC<CustomBtnProps> = ({ iconClassName, label, onClick, href, target, small }) => {
    const elem = (
        <BtnElem onClick={onClick} title={label} small={small}>
            <i className={iconClassName} aria-label={label} />
            {!small && label}
        </BtnElem>
    );
    if (href) {
        return (
            <a href={href} target={target} style={{ display: "inline-flex", justifyContent: "center" }}>
                {elem}
            </a>
        );
    }
    return elem;
};
