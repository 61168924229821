import React from "react";

import { Button } from "@op/opux";
import { IconMicrophoneOn, IconMicrophoneOff } from "@op/opux-icons/control";
import { useMessageGetter } from "react-message-context";

import useLocalAudioToggle from "../../../hooks/useLocalAudioToggle";
import useVideoContext from "../../../hooks/useVideoContext";

export const ToggleAudioButton: React.FC<{ isMobile?: boolean }> = ({ isMobile }) => {
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
    const { localTracks } = useVideoContext();
    const hasAudioTrack = localTracks.some((track) => track.kind === "audio");
    const labels = useMessageGetter("actions");
    return (
        <Button
            size={isMobile ? "default" : "large"}
            minified
            disabled={!hasAudioTrack}
            onClick={toggleAudioEnabled}
            icon={isAudioEnabled ? IconMicrophoneOn : IconMicrophoneOff}
            iconLabel={labels("toggleAudio")}
            iconProps={{ type: "default" }}
            className="control"
        />
    );
};
