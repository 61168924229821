import { useCallback, useRef, useState } from "react";

import { LocalVideoTrack } from "twilio-video";
import useVideoContext from "../useVideoContext";

export default function useLocalVideoToggle() {
    const {
        room: { localParticipant },
        localTracks,
        getLocalVideoTrack,
        onError,
        removeLocalVideoTrack,
    } = useVideoContext();
    const videoTrack = localTracks.find((track) => track.name.includes("camera")) as LocalVideoTrack;
    const [isPublishing, setIsPublishing] = useState(false);
    const previousDeviceIdRef = useRef<string>();

    const toggleVideoEnabled = useCallback(() => {
        if (!isPublishing) {
            if (videoTrack) {
                previousDeviceIdRef.current = videoTrack.mediaStreamTrack?.getSettings().deviceId;
                const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
                // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
                localParticipant?.emit("trackUnpublished", localTrackPublication);
                removeLocalVideoTrack();
            } else {
                setIsPublishing(true);
                getLocalVideoTrack({ deviceId: { exact: previousDeviceIdRef.current } })
                    .then((track: LocalVideoTrack) => localParticipant?.publishTrack(track, { priority: "high" }))
                    .catch(onError)
                    .finally(() => setIsPublishing(false));
            }
        }
    }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, onError, removeLocalVideoTrack]);

    return [!!videoTrack, toggleVideoEnabled] as const;
}
