import { useCallback, useEffect, useRef, useState } from "react";

import EventEmitter from "events";

import { isMobile } from "../../../lib/utils";
import Video, { ConnectOptions, LocalTrack, Room } from "twilio-video";
import { AppUserRole } from "../../../domain";
import { ErrorCallback } from "../../../types";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(localTracks: LocalTrack[], onError: ErrorCallback, options?: ConnectOptions) {
    const [room, setRoom] = useState<Room>(new EventEmitter() as Room);
    const [isConnecting, setIsConnecting] = useState(false);
    const localTracksRef = useRef<LocalTrack[]>([]);

    useEffect(() => {
        // It can take a moment for Video.connect to connect to a room. During this time, the user may have enabled or disabled their
        // local audio or video tracks. If this happens, we store the localTracks in this ref, so that they are correctly published
        // once the user is connected to the room.
        localTracksRef.current = localTracks;
    }, [localTracks]);

    const connect = useCallback(
        (token: string, role: AppUserRole) => {
            setIsConnecting(true);
            return Video.connect(token, { ...options, tracks: localTracks })
                .then((newRoom) => {
                    setRoom(newRoom);

                    const disconnect = () => newRoom.disconnect();

                    newRoom.once("disconnected", () => {
                        window.removeEventListener("beforeunload", disconnect);

                        if (isMobile) {
                            window.removeEventListener("pagehide", disconnect);
                        }
                        
                        // Reset the room only after all other `disconnected` listeners have been called.
                        setTimeout(() => setRoom(new EventEmitter() as Room));
                    });

                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.twilioRoom = newRoom;

                    if (role === "host") {
                        localTracksRef.current.forEach((track) =>
                            // Tracks can be supplied as arguments to the Video.connect() function and they will automatically be published.
                            // However, tracks must be published manually in order to set the priority on them.
                            newRoom.localParticipant.publishTrack(track, {
                                priority: track.kind === "video" ? "high" : "standard",
                            }),
                        );
                    }

                    setIsConnecting(false);

                    // Add a listener to disconnect from the room when a user closes their browser
                    window.addEventListener("beforeunload", disconnect);

                    if (isMobile) {
                        // Add a listener to disconnect from the room when a mobile user closes their browser
                        window.addEventListener("pagehide", disconnect);
                    }
                    newRoom.on("error", onError);
                });
        },
        [options, onError, localTracks],
    );

    return { room, isConnecting, connect };
}
