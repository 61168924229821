import { useEffect } from "react";

import { WebSocketConnectionState } from "../useWebSocket";

interface PingResponse {
    requestId?: string;
    message: string;
    status: string;
}

// How often we send a ping
const PING_INTERVAL_MS = 10_000;

export function usePing(ws: WebSocket | null, setConnectionState: (state: WebSocketConnectionState) => void) {
    useEffect(() => {
        let waiting = false;
        /**
         * Send a ping.
         */
        function sendPing() {
            if (waiting) {
                console.warn("Pong not received in time, disconnecting...");
                if (ws && ws.readyState === WebSocket.OPEN) {
                    ws.close();
                    // Update state immediately for better UX
                    setConnectionState("reconnecting");
                }
                return;
            }
            if (ws && ws.readyState === WebSocket.OPEN) {
                ws.send(JSON.stringify({ action: "ping", requestId: Date.now() + "" }));
                waiting = true;
            }
        }
        /**
         * Handle an incoming message.
         *
         * @param event Message event
         */
        function onMessage(event: MessageEvent) {
            let data: PingResponse;
            try {
                data = JSON.parse(event.data as string);
            } catch (e) {
                console.warn(e);
                return;
            }
            if (data && data.status === "OK" && data.message === "pong") {
                waiting = false;
            }
        }
        if (ws) {
            const intervalId = setInterval(() => {
                sendPing();
            }, PING_INTERVAL_MS);
            ws.addEventListener("message", onMessage);
            return () => {
                clearInterval(intervalId);
                ws.removeEventListener("message", onMessage);
            };
        }
    }, [ws, setConnectionState]);
}
