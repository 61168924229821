import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { NotFoundPage } from "./components/NotFoundPage";
import { LivePage } from "./components/LivePage";
import { Header } from "./components/Header";
import { UnauthorizedPage } from "./components/UnauthorizedPage";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { useMessageGetter } from "react-message-context";
import { Footer } from "./components/Footer";

function App() {
    const errorMessages = useMessageGetter("lobby.errors");
    return (
        <ErrorBoundary messages={errorMessages}>
            <Header />
            <Router>
                <Switch>
                    <Route path="/live/:meetingId">
                        <LivePage />
                    </Route>
                    <Route path="/unauthorized">
                        <UnauthorizedPage />
                    </Route>
                    <Route path="/*">
                        <NotFoundPage />
                    </Route>
                </Switch>
            </Router>
            <Footer />
        </ErrorBoundary>
    );
}

export default App;
