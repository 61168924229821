import React from "react";

import { Action, Button, Col, Row } from "@op/opux";
import { CircledIconPerson } from "@op/opux-icons/circled-service";
import { Modal } from "@op/opux-modal";
import { Message, useMessageGetter } from "react-message-context";
import styled from "styled-components";

import { Session } from "../../domain";

const AvatarContainer = styled.div`
    margin: 0.9rem auto;
    justify-content: center;
    align-items: center;
    display: flex;
`;

const Avatar = styled.div<{ url: string }>`
    width: 128px;
    height: 128px;
    border-radius: 50%;
    border: solid #ff6a10 2px;
    background: url(${props => props.url});
    background-size: cover;
    background-repeat: no-repeat;
`;

const InfoContainer = styled.div`
    margin: 0.9rem;
`;

interface Props {
    session: Session;
    open: boolean;
    onClose: () => void;
}

export const RealtorModal: React.FC<Props> = ({ session, open, onClose }) => {
    const misc = useMessageGetter("misc");

    const onModalClose = () => onClose();

    return (
        <Modal
            appElement={document.getElementById("root") || undefined}
            isOpen={open}
            showCloseButton
            onUpdate={(state) => (state.open ? null : onModalClose())}
            title={misc("realtor")}
            footer={
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Button compact onClick={onModalClose}>
                        <Message id="actions.close" />
                    </Button>
                </div>
            }
        >
            <Row>
                <Col xs>
                    <AvatarContainer>
                        {
                            session.hostAvatarUrl ? (
                                // This is 128x128
                                <Avatar url={session.hostAvatarUrl} />
                            ) : (
                                // This is 96x96
                                <CircledIconPerson size="large" />
                            )
                        }
                    </AvatarContainer>
                </Col>
                <Col xs>
                    <InfoContainer>
                        <h3 style={{ marginBottom: "1.2rem" }}>{session.hostName}</h3>
                        {session.hostPhone && (
                           <Action href={`tel:${session.hostPhone}`}>{session.hostPhone}</Action>
                        )}
                        {session.hostEmail && <Action href={`mailto:${session.hostEmail}`}>{session.hostEmail}</Action>}
                    </InfoContainer>
                </Col>
            </Row>
        </Modal>
    );
};
