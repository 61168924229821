import React, { useCallback, useEffect, useRef, useState } from "react";

import { Button } from "@op/opux";
import { Modal } from "@op/opux-modal";
import { useMessageGetter } from "react-message-context";
import { AudioTrack as IAudioTrack } from "twilio-video";

import { useAppState } from "../../state";
import useTermsOfServiceContext from "../../hooks/useTermsOfServiceContext";

interface AudioTrackProps {
    track: IAudioTrack;
}

export default function AudioTrack({ track }: AudioTrackProps) {
    const { activeSinkId } = useAppState();
    const audioEl = useRef<HTMLAudioElement>();
    const modalEl = useRef<any>();
    const [modalOpen, setModalOpen] = useState(false);
    const { open: termsOfServiceOpen, setOnCloseHandler } = useTermsOfServiceContext();
    const messages = useMessageGetter("autoplayPolicy");

    // Function to call when autoplay policy kicks in
    const playAudio = useCallback(() => {
        audioEl.current?.play().catch((e) => console.error(e));
        setModalOpen(false);
    }, []);

    // Subscribe to terms of service dialog on close -event
    useEffect(() => {
        // Important: Pass a function that returns a function when using hook
        setOnCloseHandler(() => playAudio);
        return () => {
            setOnCloseHandler(null);
        };
    }, [playAudio, setOnCloseHandler]);

    // Check if user interaction is needed because of browser autoplay policy
    useEffect(() => {
        const el = track.attach();
        audioEl.current = el;
        audioEl.current.setAttribute("data-cy-audio-track-name", track.name);
        document.body.appendChild(audioEl.current);
        isUserInteractionRequired(el).then((isRequired) => {
            setModalOpen(isRequired);
        });
        return () => track.detach().forEach((el) => el.remove());
    }, [track]);

    useEffect(() => {
        audioEl.current?.setSinkId?.(activeSinkId);
    }, [activeSinkId]);

    // Open modal if user interaction is needed
    useEffect(() => {
        if (modalEl.current) {
            if (modalOpen && !termsOfServiceOpen) {
                modalEl.current.open();
            } else {
                modalEl.current.close();
            }
        }
    }, [modalEl, modalOpen, termsOfServiceOpen]);

    return (
        <Modal
            appElement={document.getElementById("root") || undefined}
            title={messages("title")}
            footer={
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Button compact onClick={playAudio}>
                        {messages("accept")}
                    </Button>
                </div>
            }
            ref={modalEl}
            onUpdate={(state) => setModalOpen(state.open)}
        >
            <div>{messages("description")}</div>
        </Modal>
    );
}

/**
 * Check if browser's autoplay policy causes any trouble. Sourced:
 * https://github.com/twilio/twilio-video.js/blob/master/COMMON_ISSUES.md#working-around-the-browsers-autoplay-policy
 *
 * @param audioEl Audio element
 */
async function isUserInteractionRequired(audioEl: HTMLAudioElement): Promise<boolean> {
    if (!audioEl.paused) {
        return false;
    }
    if (audioEl.hasAttribute("autoplay")) {
        await Promise.race([
            new Promise((resolve) => (audioEl.onplay = resolve)),
            new Promise((resolve) => setTimeout(resolve, 500)),
        ]);
        return audioEl.paused;
    }
    try {
        await audioEl.play();
        return false;
    } catch (e) {
        return e.name === "NotAllowedError";
    }
}
